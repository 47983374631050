import React from "react";
import { Link } from "react-router-dom";

export default function Footer({ footer, globals }) {
  return (
    <footer className="bg-dark text-inverse">
      <div className="container py-13 py-md-15">
        <div className="row gy-6 gy-lg-0">
          <div className="col-lg-4">
            <div className="widget">
              <h2 className="text-white">Manawatu Roofing</h2>
              <p className="mb-2">© 2022 Manawatu Roofing. All rights reserved.</p>
              <div>
                Site by{" "}
                <a href="https://headless.co.nz" target="_blank" rel="noreferrer" style={{ color: "white", textDecoration: "underline" }}>
                  Headless
                </a>{" "}
                powered by{" "}
                <a href="https://headlesshost.com" target="_blank" rel="noreferrer" style={{ color: "white", textDecoration: "underline" }}>
                  Headlesshost
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-2 offset-lg-2">
            <div className="widget">
              <h4 className="widget-title mb-3 text-white">Need Help?</h4>
              <ul className="list-unstyled mb-0">
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 col-lg-2">
            <div className="widget">
              <h4 className="widget-title mb-3 text-white">Learn More</h4>
              <ul className="list-unstyled mb-0">
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/terms">Terms</Link>
                </li>
                <li>
                  <Link to="/guarantees">Guarantees</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 col-lg-2">
            <div className="widget">
              <h4 className="widget-title mb-3 text-white">Get in Touch</h4>
              Matt Hopkins
              <br />
              <a href="mailto:info@manawaturoofing.co.nz">info@manawaturoofing.co.nz</a>
              <br />
              <a href="tel:027 569 4741">027 569 4741</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
